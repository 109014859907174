import { Controller } from "stimulus"
import I18n from 'i18n-js';

export default class extends Controller {
  /*
  form-validation controller allows to verify different field in a form,
  while global this.wrong_inputs set has elements, submit button will be disabled
  */
  static targets = [ 'submitButton' ]
  static values = { initialWrongInputs: Array }

  initialize(){
    if (this.initialWrongInputsValue) {
      this.wrong_inputs = new Set(this.initialWrongInputsValue)
    } else {
      this.wrong_inputs = new Set()
    }
  }

  async call(event){
    let params = JSON.parse(event.currentTarget.dataset.params).call
    const validate_enable_submit = params.validate_enable_submit

    await this.validateInput(params)
    if (validate_enable_submit) this.enableSubmit()
  }

  trigger_required_fields_call(event) {
    let required_fields = JSON.parse(event.currentTarget.dataset.params).required_fields
    required_fields.forEach(function (hash) {
      let field = document.getElementById(hash.id);
      field.dispatchEvent(new Event(hash.event))
    });
    this.enableSubmit()
  }

  async validateInput(params){
    let valid = null

    // For future validations
    switch (params.validation) {
      case 'fileExtension':
        valid = this.fileExtension(params.validation_params)
        break;
      case 'presence':
        valid = this.presence(params.input_id)
        break;
      case 'rut':
        valid = await this.rut(params.input_id)
        break;
      case 'refresh':
        valid = true;
        break;
      case 'presence_value_positive':
        valid = this.presenceValuePositive(params.input_id)
        break;
      case 'no_null_option':
        valid = this.noNullOption(params.input_id)
        break;
      case 'value_less_or_equal_than_limit':
        valid = this.valueLessOrEqualThanLimit(params.input_id)
        break;
      case 'uniqueness_supplier_rut':
        valid = await this.uniquenessSupplierRut(params.input_id, params.community_id, params.model_id, params.country_code)
        break;
    }

    if (valid && params.warning_params) {
      this.removeWarning(params.warning_params)
    } else {
      this.addWarning(params.warning_params)
    }
  }

  fileExtension(params){
    let filename = ''
    if (params.filename_id) {
      filename = document.getElementById(params.filename_id).value
    } else {
      filename = document.getElementsByName(params.filename_name)[0].value;
    }
    let extension = filename.split('.').pop().toLowerCase();

    return filename == '' || params.permitted.includes(extension)
  }

  presence(input_id){
    return document.getElementById(input_id).value.length > 0
  }

  presenceValuePositive(input_id){
    const element = document.getElementById(input_id)
    return element.value.length > 0 && element.value > 0
  }

  noNullOption(input_id){
    let element = document.getElementById(input_id)
    return element.value != 'null_option'
  }

  valueLessOrEqualThanLimit(input_id){
    let element = document.getElementById(input_id)
    let value = parseFloat(element.value)
    let limit = parseInt(element.dataset.valuelimit)

    return value <= limit
  }

  rut(input_id){
    let rut = document.getElementById(input_id).value

    if(rut.length == 0) return true

    let params = new URLSearchParams({ rut: JSON.stringify(rut) })

    return fetch('/validate_rut.json' + '?' + params, {
      method: 'GET',
      headers: { 'contentType': 'application/json',
                 'Accept': 'application/json',
                 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    })
    .then(response => response.json())
    .then(data => { return data.valid })
  }

  uniquenessSupplierRut(input_id, community_id, model_id, country_code){
    let rut = document.getElementById(input_id).value
    if(rut.length == 0) return true

    let params = new URLSearchParams({ rut: JSON.stringify(rut), community_id: JSON.stringify(community_id), model_id: JSON.stringify(model_id), country_code: JSON.stringify(country_code) });

    return fetch('/validate_unique_rut.json' + '?' + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.exists) {
        return false;
      } else {
        return true;
    }
    });
  }

  removeWarning(params){
    let input = document.getElementById(params.input_id);
    let input_button = document.getElementById(params.input_button_id);
    let warning = document.getElementById(params.warning_id);
    let info = document.getElementById(params.info_id);
    const invalid_input_class = params.invalid_input_class || 'invalid_input'

    if(warning != null) warning.style.display = 'none';
    if(info != null) info.style.display = 'block';
    input.classList.remove(invalid_input_class);
    if(input_button != null) input_button.classList.remove(invalid_input_class)
    this.wrong_inputs.delete(params.input_id)
  }

  addWarning(params){
    let input = document.getElementById(params.input_id);
    let input_button = document.getElementById(params.input_button_id);
    let warning = document.getElementById(params.warning_id);
    let info = document.getElementById(params.info_id);
    const invalid_input_class = params.invalid_input_class || 'invalid_input'

    if(warning != null) warning.style.display = 'block';
    if(info != null) info.style.display = 'none';
    if(!input.classList.contains(invalid_input_class)) input.classList.add(invalid_input_class);
    if(input_button != null) input_button.classList.add(invalid_input_class);
    this.wrong_inputs.add(params.input_id)
  }

  enableSubmit(params){
    if (this.wrong_inputs.size == 0) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.remove('disabled');
    } else {
      this.submitButtonTarget.disabled = true;
      if(!this.submitButtonTarget.classList.contains('disabled')) this.submitButtonTarget.classList.add('disabled')
    }
  }

  removeInputField(event){
    let params = JSON.parse(event.currentTarget.dataset.params)
    params.remove.forEach(input_id => {
      this.wrong_inputs.delete(input_id)
    });

    this.enableSubmit(params.call)
  }

  // Removes the validations that are sent in the remove parameter, when the field value is included
  // to the value sent in the condition_values parameter
  removeInputFieldConditionally(event){
    let value = event.currentTarget.value
    let condition_values = JSON.parse(event.currentTarget.dataset.params).condition_values
    let validations = JSON.parse(event.currentTarget.dataset.params).remove

    if( condition_values.includes(value) ){
      validations.forEach(validation => {
        this.wrong_inputs.delete(validation)
      })

      this.enableSubmit()
    }
  }

  resetRequiredField(event){
    const rutField = event.currentTarget;
    if (rutField.value === '') {
      rutField.setCustomValidity(this.errorMessage());
      return false;
    }
    rutField.setCustomValidity('');
    return true;
  }

  errorMessage(){
    return I18n.t('required_field');
  }
}
